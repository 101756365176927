import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import firebase from "firebase/compat";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {HttpClient,} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {DialogService} from "../../../tandem-core/services/dialog.service";
import {BehaviorSubject, filter, from, switchMap, takeUntil, timer} from "rxjs";
import {tap} from "rxjs/operators";

@Component({
  selector: 'tandem-email-not-verified',
  templateUrl: './email-not-verified.component.html',
  styleUrls: ['./email-not-verified.component.scss']
})
export class EmailNotVerifiedComponent implements OnInit, OnDestroy {
  public user: firebase.User | null = null;
  private destroy$ = new BehaviorSubject<boolean>(false);

  userEmail = ''
  constructor(
    private auth: AuthService,
    private afAuth: AngularFireAuth,
    private router: Router,
    private dialogService: DialogService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    // Set up initial auth state
    this.afAuth.authState.pipe(
      takeUntil(this.destroy$)
    ).subscribe(user => {
      this.user = user;
      this.userEmail = user?.email || '';
    });

    // console.log('setting up timer');
    // Set up verification check polling
    timer(0, 5000).pipe(
      // tap(() => console.log('Timer tick')), // Add this to see if timer is firing
      switchMap(() => {
        // console.log('About to call checkEmailVerification');
        return from(this.checkEmailVerification());
      }),
      takeUntil(this.destroy$) // Add takeUntil to stop the timer on destroy
    ).subscribe({
      next: () => {
        // console.log('Verification check completed')
      },
      error: (err) => console.error('Error in verification check:', err)
    });

    // Watch auth stream for verification status
    this.auth.$user.pipe(
      takeUntil(this.destroy$),
      filter(user => !!user?.verified)
    ).subscribe(() => {
      this.router.navigate(['/auth/additional-info']);
    });
  }

  ngOnDestroy(): void {
    // console.log('Component destroying');
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private async checkEmailVerification(): Promise<void> {
    // console.log('method: checkEmailVerification - started');
    const user = await this.afAuth.currentUser;
    // console.log('Current user:', user?.uid);
    if (user) {
      await this.auth.updateUserVerificationStatus(user.uid);
      // console.log('Verification status updated');
    }
  }

  async resendVerification() {
    if (!this.user) return;

    this.dialogService.showLoadingDialog('Please wait, we\'re sending you another email.');

    try {
      await this.auth.resendEmailVerification(this.user.uid).toPromise();

      this.dialogService.openModal2({
        title: 'Resent Email',
        content: `Successfully sent a verification link to ${this.user.email}. Please check your inbox again.`,
        type: 'success',
        actions: [{
          text: 'Close',
          role: 'close',
          callback: () => this.dialogService.closeModal2()
        }]
      });
    } catch (error) {
      this.dialogService.openConfirmDialog(
        'Give us a few Minutes',
        'Due to security risks, our database limits the number of email verification messages sent in a given time window. ' +
        'Please wait a few minutes and then try requesting another email verification.\n\n' +
        'Alternatively, you can send a message to support to verify your account. ' +
        'Keep in mind it may be a few hours before customer support is able to address your request.\n\n' +
        'We appreciate your patience, you\'ll be crushing your financial goals in no time!',
        '',
        'Close',
        true,
        false,
        false,
        true
      );
    }
  }

  logout() {
    this.auth.onLogout().then(() =>
      this.router.navigate(['auth/login'])
    );
  }

  protected readonly environment = environment;
}

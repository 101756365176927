<!--<div class="w-full flex flex-col justify-center items-center" style="height: 100vh">-->
<!--  <div class="relative border-2 border-primary rounded-sm flex flex-col items-center gap-30 w-10/12 md:w-1/2">-->
<!--    &lt;!&ndash; Heading Wrapper &ndash;&gt;-->
<!--    <div class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-background px-20">-->
<!--      <h2>Verify Email</h2>-->
<!--    </div>-->
<!--    &lt;!&ndash; Content &ndash;&gt;-->
<!--    <div class="w-3/4 p-20 md:p-60 flex flex-col gap-20 md:gap-60">-->
<!--      <h4>Thank you for registering with Tandem Finance! We're excited to have you as part of our community, and we hope you're excited to be here!-->
<!--        To complete your registration and ensure the security of your account, we've sent a verification link to the email address you provided during sign-up.</h4>-->
<!--      <h4>Didn't receive an email? </h4>-->
<!--      <div class="flex flex-row justify-center gap-15">-->
<!--        <tandem-button class="inline-block" theme="outline" color="primary" [onClick]="resendVerification.bind(this)">Resend verification</tandem-button>-->
<!--&lt;!&ndash;        <tandem-button class="inline-block" theme="solid" color="primary" [onClick]="refresh.bind(this)">Refresh</tandem-button>&ndash;&gt;-->
<!--      </div>-->
<!--      <div class="flex flex-row justify-center gap-15">-->
<!--        <h5>Not {{user?.email}}?</h5>-->
<!--        <tandem-button theme="solid" color="primary" (click)="logout()">Logout</tandem-button>-->
<!--      </div>-->
<!--      <h4 *ngIf="resent">Successfully sent a verification link to {{user?.email}}. Please check your inbox again.</h4>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="flex min-h-full flex-col justify-center py-12 px-5 sm:px-6 lg:px-8" style="height: 100vh">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img [src]="environment.primaryIconPath" class="mx-auto h-40 w-auto">
    <h2 class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Verify Email</h2>
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
    <div class="bg-white px-6 py-12 shadow rounded-lg sm:px-12">
      <div class="flex flex-col items-center justify-center gap-4">
        <h4>Thank you for registering with Tandem Finance! We're excited to have you as part of our community, and we hope you're excited to be here!
          To complete your registration and ensure the security of your account, we've sent a verification link to the email address you provided during sign-up. After clicking that link, come back to this screen. You might have to refresh your screen after verifying.</h4>
        <div class="my-8 flex flex-col gap-4">
          <h4>Didn't receive an email? </h4>
          <div class="flex flex-row justify-center">
            <tandem-button class="inline-block" theme="outline" color="primary" [onClick]="resendVerification.bind(this)">Resend verification</tandem-button>
            <!--        <tandem-button class="inline-block" theme="solid" color="primary" [onClick]="refresh.bind(this)">Refresh</tandem-button>-->
          </div>
        </div>

        <div class="flex flex-row justify-center items-center gap-4">
          <h5>Not {{user?.email}}?</h5>
          <tandem-button theme="solid" color="primary" (click)="logout()">Logout</tandem-button>
        </div>
      </div>
    </div>
  </div>
</div>


